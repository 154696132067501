import React from 'react';
import Button from '@mui/material/Button';
import './App.css';
import { sepolia as chain } from "viem/chains";
import {
  CaveatStruct,
  createExecution,
  createOpenRootDelegation,
  DelegationStruct,
  type DeleGatorClient,
  Hex,
  UserOperationReceiptResponse,
} from "@codefi/delegator-core-viem";
import { allGatorPasskeyAccounts, GatorWebAuthnAccount, hasGatorPasskeyAccounts, loadGatorPasskeyAccount, newGatorPasskeyAccount } from '../../gator';
import { alpha, Divider, MenuItem, OutlinedInputProps, Select, styled, TextField, TextFieldProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { deflate, inflate } from 'pako';
import Swal from 'sweetalert2';
import './index.css';
import useIFrameCommunication, { JsonRpcJSError } from '../../comms/useIFrameCommunication';

/**
 * Compresses a string and encodes it using Base64.
 * @param input - The string to be compressed and encoded.
 * @returns The compressed and Base64-encoded string.
 */
function compressAndEncodeBase64(input: string): string {
  // Compress the input string
  const compressed = deflate(input);

  // Convert the compressed data to a Base91 string
  const base91Encoded = uint8ArrayToBase64(compressed);

  const urlEncoded = encodeURIComponent(base91Encoded);

  return urlEncoded;
}

/**
 * Converts a Uint8Array to a Base64 string.
 * @param uint8Array - The Uint8Array to convert.
 * @returns The Base64-encoded string.
 */
function uint8ArrayToBase64(uint8Array: Uint8Array): string {
  let binary = '';
  for (let i = 0; i < uint8Array.length; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }
  return btoa(binary);
}

/**
 * Converts a Base64 string to a Uint8Array.
 * @param base64 - The Base64 string to convert.
 * @returns The Uint8Array.
 */
function base64ToUint8Array(base64: string): Uint8Array {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

/**
 * Decodes a URL-encoded Base91 string, decompresses it using zlib (pako), and parses it back into a JSON string.
 * @param input - The URL-encoded Base91 string to be decoded and decompressed.
 * @returns The decompressed and parsed JSON string.
 */
function decodeAndDecompressBase91Url(input: string): string {
  // URL decode the input string
  const base64Encoded = decodeURIComponent(input);

  // Decode the Base64 string to a Uint8Array
  const compressed = base64ToUint8Array(base64Encoded);

  // Decompress the Uint8Array using pako
  const decompressed = inflate(compressed, { to: 'string' });

  return decompressed;
}

const StyledTextField = styled((props: TextFieldProps) => (
  <TextField
    slotProps={{
      input: { disableUnderline: true } as Partial<OutlinedInputProps>,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    border: '1px solid',
    backgroundColor: '#191C20',
    color: "white",
    borderColor: '#E0E3E7',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
      borderColor: '#2D3843',
    }),
  },
}));

const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
`;

const StyledItem = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: center;
`;

const ButtonGroup = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
`;

const StyledToggleButton = styled(ToggleButton)`
  color: white;
`;

const StyledIFrame = styled("iframe")`
  border: none;
  display: none;
`;

type LoginOrCreateProps = {
  setDelegator: React.Dispatch<React.SetStateAction<DeleGatorClient | undefined>>;
  setAccount: React.Dispatch<React.SetStateAction<GatorWebAuthnAccount | undefined>>;
}

function runWhenWindowReady(win: Window, callback: () => void) {
  const interval = setInterval(() => {
    console.log(`Checking window`)
    if (win.closed) {
      console.log("Window closed")
      clearInterval(interval);
    } else if (win.document.readyState === 'complete') {
      console.log("Window loaded, invoking callback")
      callback();
      clearInterval(interval);
    }
  }, 100);
}

function LoginOrCreate({ setDelegator, setAccount }: LoginOrCreateProps) {
  const [selectedAccount, setSelectedAccount] = React.useState<string>("");
  const [accountName, setAccountName] = React.useState<string>("");
  const [isError, setIsError] = React.useState(false);
  const [usePopup, setUsePopup] = React.useState(false);
  const { connected, sendMessage, windowReady, initWindow } = useIFrameCommunication({
    allowedOrigin: "https://gator.edkek.com"
  });
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  const updateAccountName = React.useCallback((val: string) => {
    setAccountName(val);
  }, [setAccountName]);

  const createWallet = React.useCallback(async () => {
    if (!accountName || accountName.trim() === "") {
      setIsError(true);
      console.error('error');
      return;
    }

    setIsError(false);

    const gatorAccount = await newGatorPasskeyAccount({
      walletName: accountName,
      rp: "localhost",
      chain,
    });

    setDelegator(gatorAccount.delegator);
    setAccount(gatorAccount);
  }, [setDelegator, setAccount, setIsError, accountName]);

  const loginWallet = React.useCallback(async () => {
    if (!selectedAccount) {
      throw new Error("No selected account name");
    }

    const gatorAccount = await loadGatorPasskeyAccount({
      walletName: selectedAccount,
      rp: "localhost",
      chain,
    });

    setDelegator(gatorAccount.delegator);
    setAccount(gatorAccount);
  }, [setDelegator, setAccount, selectedAccount]);

  const remoteCreate = React.useCallback(async () => {
    if (!connected()) return;

    try {
      const response = await sendMessage("test", {
        hello: "world"
      });

      await Swal.fire("Success", "New wallet created", "success");
    } catch (e: any) {
      if ((e?.message as string).toLowerCase().includes("method not supported")) {
        // show a popup window
        const url = 'https://gator.edkek.com/popup?origin=https://gatordemo.edlogs.io'; // URL to open in the popup
        const windowName = 'Gator Wallet'; // Name of the popup window
        const windowFeatures = 'width=357,height=600'; // Size of the popup window

        const win = window.open(url, windowName, windowFeatures);

        if (win) {
          initWindow(win);

          setTimeout(() => remoteCreate().then(() => win.close()), 1000);

          setUsePopup(true);
          return;
        }
      }

      console.error(e);
      await Swal.fire("Error", `There was an error ${e?.message ? `:${e.message}` : ""}`, "error")
    }
  }, [connected, sendMessage]);

  const remoteLogin = React.useCallback(async () => {
    if (!connected()) return;

    try {
      const response = await sendMessage("test2", {
        hello: "world"
      });

      await Swal.fire("Success", "Wallet successfully authenticated", "success");
    } catch (e: any) {
      if ((e?.message as string).toLowerCase().includes("method not supported")) {
        // show a popup window
        const url = 'https://gator.edkek.com/popup?origin=https://gatordemo.edlogs.io'; // URL to open in the popup
        const windowName = 'Gator Wallet'; // Name of the popup window
        const windowFeatures = 'width=357,height=600'; // Size of the popup window

        const win = window.open(url, windowName, windowFeatures);

        if (win) {
          initWindow(win);

          // now try again
          setTimeout(() => remoteLogin().then(() => win.close()), 1000);

          setUsePopup(true);
          return;
        }
      }

      console.error(e);
      await Swal.fire("Error", `There was an error ${e?.message ? `:${e.message}` : ""}`, "error")
    }
  }, [connected, sendMessage]);

  React.useEffect(() => {
    if (usePopup) return;

    if (iframeRef.current !== null && iframeRef.current.contentWindow !== null && !windowReady()) {
      initWindow(iframeRef.current.contentWindow);
    }
  }, [iframeRef.current, usePopup])
  
  const hasAccounts = hasGatorPasskeyAccounts();
  const allAccounts = allGatorPasskeyAccounts();

  return (
    <StyledContainer>
      <p>
        Create or Login to your Gator Wallet
      </p>

      <h3>
        Web Wallet
      </h3>
      <Divider />
      <Button variant="contained" onClick={remoteCreate}>Create New Web Wallet</Button>
      <Button variant="contained" onClick={remoteLogin}>Login Web Wallet</Button>
      { !usePopup && <StyledIFrame ref={iframeRef} src='https://gator.edkek.com/iframe?origin=https://gatordemo.edlogs.io' allow="publickey-credentials-create *; publickey-credentials-get *" /> }
      <h3>Embedded Wallet</h3>
      <Divider />
      <ButtonGroup>
        <StyledTextField color='primary' variant='filled' error={isError} value={accountName} onChange={(evt) => updateAccountName(evt.target.value)} />
        <Button variant="contained" onClick={createWallet}>Create New Wallet</Button>
      </ButtonGroup>

      {
        hasAccounts && (
          <ButtonGroup>
            <Select value={selectedAccount} onChange={(evt) => setSelectedAccount(evt.target.value)}>
              {
                allAccounts.map((accountName) => (
                  <MenuItem value={accountName}>{accountName}</MenuItem>
                ))
              }
            </Select>
            <Button variant="contained" onClick={loginWallet}>Login</Button>
          </ButtonGroup>
        )
      }
    </StyledContainer>
  )
}

type DelegationRedeemProps = {
  encodedData: string;
  account: GatorWebAuthnAccount;
}

function DelegationRedeem({ account, encodedData }: DelegationRedeemProps) {
  const [loading, setLoading] = React.useState(false);

  const signedDelegation = React.useMemo(() => {
    const json = decodeAndDecompressBase91Url(encodedData);

    return JSON.parse(json) as DelegationStruct;
  }, [encodedData]);

  const redeem = React.useCallback(async () => {
    setLoading(true);

    const action = createExecution("0x0000000000000000000000000000000000000000");
    const userOp = await account.delegator.createRedeemDelegationsSingleDefaultUserOp([signedDelegation], action, await account.gasFees());

    const result = await account.sponsorAndSendUserOperation(userOp);

    if (!result.success) {
      await Swal.fire({
        title: "Error",
        text: `UserOperation failed ${result.reason}`,
        icon: "error"
      })
    } else {
      await Swal.fire({
        title: "Redeem Successful",
        text: "The delegation redeemtion was successful",
        icon: "success"
      })
    }
  }, [account, signedDelegation, setLoading]);

  return (
    <StyledContainer>
      <p>
        Redeem delegation
      </p>

      <StyledItem>
        <p>Delegator: </p>
        <b>{signedDelegation.delegator}</b>
      </StyledItem>
      <StyledItem>
        <p>Delegatee:</p>
        <b>{signedDelegation.delegate === "0xa11" ? "Open" : signedDelegation.delegate}</b>
      </StyledItem>
      <StyledItem>
        <p>Caveats:</p>
        <p><b>{signedDelegation.caveats.length}</b> Caveats</p>
      </StyledItem>
      <LoadingButton loading={loading} onClick={redeem}>Redeem</LoadingButton>
    </StyledContainer>
  )
}

type TokenType = "Native" | "ERC20"

type DelegationBuilderProps = {
  account: GatorWebAuthnAccount;
}

function BuildDelegation({ account }: DelegationBuilderProps) {
  const [tokenType, setTokenType] = React.useState<TokenType>("Native");
  const [tokenAddress, setTokenAddress] = React.useState("");
  const [amount, setAmount] = React.useState<number | "">(0);
  const [loading, setLoading] = React.useState(false);

  const createDelegationFromForm = React.useCallback(async () => {
    setLoading(true);
    let caveats: CaveatStruct[] = [];
    const caveatBuilder = account.delegator.createCaveatBuilder();
    if (tokenType === "ERC20") {
      caveats = caveatBuilder
        .addCaveat("erc20TransferAmount", tokenAddress as Hex, BigInt(amount))
        .addCaveat("limitedCalls", 1)
        .build();
    } else {
      caveats = caveatBuilder
        .addCaveat("nativeTokenTransferAmount", BigInt(amount))
        .addCaveat("limitedCalls", 1)
      .build();
    }

    const delegation = createOpenRootDelegation(account.delegator.account.address, caveats);

    if (!account.credential.isDeployed) {
      await Swal.fire({
        title: "Gator Deployment Required",
        text: "Gator will be deployed before signing delegation",
        icon: "info"
      });

      await account.deploy();
    }

    const signedDelegation = await account.delegator.signDelegation(delegation);

    const delegateJson = JSON.stringify(signedDelegation, (key, value) =>
      typeof value === "bigint" ? value.toString() : value,);

    const urlData = compressAndEncodeBase64(delegateJson);
  
    const url = `https://${window.location.host}/?delegation=${urlData}`

    setLoading(false);

    try {
      await navigator.clipboard.writeText(url);

      await Swal.fire({
        title: "Delegation Link Created",
        text: "The usable delegation link has been copied to your clipboard",
        icon: "success"
      })
    } catch {
      await Swal.fire({
        title: "Delegation Link Created",
        text: `The usable delegation link is: ${url}`,
        icon: "success"
      })
    }

  }, [amount, tokenType, tokenAddress, account]);

  return (
    <StyledContainer>
      <p>
        Build a new delegation
      </p>

      <StyledItem>
        <p>Token Type</p>
        <ToggleButtonGroup
          color="primary"
          value={tokenType}
          exclusive
          onChange={(_, value) => setTokenType(value)}
          aria-label="Token Type"
        >
          <StyledToggleButton value="Native">Native</StyledToggleButton>
          <StyledToggleButton value="ERC20">ERC20</StyledToggleButton>
        </ToggleButtonGroup>
      </StyledItem>

      { 
        tokenType === "ERC20" ? (
          <StyledItem>
            <p>Token Address</p>
            <StyledTextField value={tokenAddress} onChange={(evt) => setTokenAddress(evt.target.value)} />
          </StyledItem>
        ) : <></>
      }

      <StyledItem>
        <p>Amount</p>
        <StyledTextField variant='filled' value={amount} onChange={(evt) => setAmount(
        evt.target.value === "" ? "" : !Number.isNaN(Number(evt.target.value)) 
          && Number.isFinite(Number(evt.target.value)) ? Number(evt.target.value) : ""
        )} type="number" />
      </StyledItem>

      <LoadingButton loading={loading} onClick={createDelegationFromForm}>Create Delegation Link</LoadingButton>
    </StyledContainer>
  )
}

function App() {
  const [delegator, setDelegator] = React.useState<DeleGatorClient>();
  const [account, setAccount] = React.useState<GatorWebAuthnAccount>();
  const [isDeploying, setIsDeploying] = React.useState(false);
  const [userOperationReceipt, setUserOperationReceipt] = React.useState<UserOperationReceiptResponse>();

  const queryParameters = new URLSearchParams(window.location.search)
  const delegation = queryParameters.get("delegation")

  console.log(`delegation in url: ${delegation}`)

  const handleDeployDelegator = React.useCallback(async () => {
    if (!delegator || !account) {
      console.log("No delegator");
      return;
    }
    console.log("Starting deploy");
    setIsDeploying(true);

    const result = await account.deploy();

    setIsDeploying(false);
    setUserOperationReceipt(result);
  }, [setIsDeploying, setUserOperationReceipt, delegator]);

  return (
    <div className="App">
      <header className="App-header">
          <div className='btnGroup'>
          {
            account === undefined ? 
              (
                <LoginOrCreate setDelegator={setDelegator} setAccount={setAccount} />
              ) :
              (
                delegation !== null ?
                  (
                    <DelegationRedeem account={account} encodedData={delegation} />
                  ) : (
                  <BuildDelegation account={account} /> )
              )
          }
        </div>
      </header>
    </div>
  );
}

export default App;
